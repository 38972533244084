import PropTypes from 'prop-types'
import { Fragment, useMemo, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

const SelectMenu = ({
  id = 'location',
  label = undefined,
  description = undefined,
  options = [],
  className,
  onChange,
  defaultValue = undefined,
  disabled = false,
  translate = true,
  optionsPosition = 'bottom',
  classNames = ''
}) => {
  const { t } = useTranslation()

  const [selectedKey, setSelectedKey] = useState(defaultValue)

  const selectedValue = useMemo(() => {
    const findOption = options.find(([key]) => key === selectedKey)

    return {
      value: translate ? t(findOption[1]) : findOption[1],
      state: findOption[2]
    }
  }, [selectedKey, options, translate, t])

  const handleChange = (value) => setSelectedKey(value) || onChange(value)

  return (
    <div className={className}>
      <Listbox value={selectedKey} onChange={(value) => handleChange(value)}>
        {label && (
          <Listbox.Label className='block text-sm font-medium text-gray-700'>
            {label}
          </Listbox.Label>
        )}
        {!disabled && (
          <div className={classNames('relative', label && 'mt-2')}>
            <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer text-sm'>
              <span className='block truncate'>{selectedValue.value}</span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                id={id}
                className={classNames(
                  'absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto text-sm',
                  optionsPosition === 'bottom' && 'mt-1',
                  optionsPosition === 'top' && 'mb-1 bottom-full'
                )}
              >
                {options.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={option[0]}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {translate ? t(option[1]) : option[1]}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
        {disabled && (
          <span className='mt-2 text-lg font-semibold'>
            {selectedValue.value}
          </span>
        )}
        {description && (
          <p className='text-gray-700 text-xs mt-2 italic'>{description}</p>
        )}
      </Listbox>
    </div>
  )
}

SelectMenu.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.array, // [ key, value ]
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  translate: PropTypes.bool,
  optionsPosition: PropTypes.string,
  classNames: PropTypes.string
}

export default SelectMenu
